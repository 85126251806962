export function handleMark(data) {
    if (!data) {
        return '-';
    }
    data = data.replace(/'/g, ' ');
    data = data.replace(/{/g, ' ');
    data = data.replace(/}/g, ' ');
    data = data.replace(/,/g, '|');
    return data;
}

export default  { handleMark }
